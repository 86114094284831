/* eslint-disable no-undef */
import React, { useState } from 'react';

export default ({
  roomId,
}) => {
  const url = `${window.location.protocol}//${window.location.host}/join/${roomId}`;
  const [shareableLinkField, setShareableLinkField] = useState(null);
  const copyToClipboard = () => {
    if (!shareableLinkField) {
      return;
    }
    shareableLinkField.select();
    // eslint-disable-next-line no-undef
    document.execCommand('copy');
  };

  return (
    <div className="input-group">
      <span className="input-group-addon">Share Link</span>
      <input
        className="form-input"
        type="text"
        readOnly
        ref={setShareableLinkField}
        value={url}
      />
      <button
        type="button"
        className="btn input-group-btn"
        onClick={copyToClipboard}
      >
        <i className="fas fa-clipboard" />
      </button>
    </div>
  );
};
