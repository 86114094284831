import React, { useEffect, useRef, useState } from 'react';
import Button from '../utils/Button';
import Input from '../utils/Input';
import Table from '../utils/Table';
import Sketchpad from './Sketchpad';

export default ({
  firestore,
  analytics,
  roomDoc,
  playerDoc,
  playerDocs,
}) => {
  const [inputPoints, setInputPoints] = useState([]);
  const [clearTime, setClearTime] = useState(Date.now());
  const [storyPreviousRound, setStoryPreviousRound] = useState({
    name: '',
    input: '',
    points: [],
  });
  const submitButtonGuessFieldRef = useRef(null);
  const inputPromptRef = useRef(null);

  // listen for story
  useEffect(() => {
    if (!firestore
      || !roomDoc || !roomDoc.exists
      || !playerDoc || !playerDoc.exists
    ) {
      return;
    }
    const moveToNextRound = async () => {
      const storyDoc = await firestore.collection('rooms').doc(roomDoc.id)
        .collection('stories').doc(`${playerDoc.data().storyIndex}`)
        .get();
      const story = storyDoc.data();
      const index = `${roomDoc.data().round - 1}`;
      if (story && story[index]) {
        setStoryPreviousRound(story[index]);
      }
    };
    moveToNextRound();
  }, [firestore, playerDoc, roomDoc]);

  const submitWriting = async (input) => {
    const sanitizedInput = input.trim();
    if (sanitizedInput.length === 0) {
      return;
    }
    const roomRef = firestore.collection('rooms').doc(roomDoc.id);
    const playerRef = roomRef.collection('players').doc(playerDoc.id);
    const storyRef = roomRef.collection('stories').doc(`${playerDoc.data().storyIndex}`);
    const storyUpdate = {};
    storyUpdate[`${roomDoc.data().round}`] = {
      prompt: sanitizedInput,
      name: playerDoc.id,
    };
    await storyRef.set(storyUpdate, { merge: true });
    await playerRef.update({
      state: 'SUBMITTED',
      prompt: sanitizedInput,
    });
    if (inputPromptRef && inputPromptRef.current) {
      inputPromptRef.current.value = '';
    }
  };

  const submitDrawing = async () => {
    analytics.logEvent('telestrations_drawing_submit', {
      num_points: inputPoints.length,
    });
    const roomRef = firestore.collection('rooms').doc(roomDoc.id);
    const playerRef = roomRef.collection('players').doc(playerDoc.id);
    const storyRef = roomRef.collection('stories').doc(`${playerDoc.data().storyIndex}`);
    const storyUpdate = {};
    storyUpdate[`${roomDoc.data().round}`] = {
      points: inputPoints,
      name: playerDoc.id,
    };
    await storyRef.set(storyUpdate, { merge: true });
    await playerRef.update({
      state: 'SUBMITTED',
      points: inputPoints,
    });
    setInputPoints([]);
  };

  const clearDrawing = () => {
    setInputPoints([]);
    setClearTime(Date.now());
  };

  const addPoint = (point) => {
    inputPoints.push({
      x: point.x,
      y: point.y,
    });
  };

  const nextState = () => {
    const url = new URL('https://us-central1-trivia-03212020.cloudfunctions.net/telestrations-nextState');
    url.searchParams.append('roomId', roomDoc.id);
    // eslint-disable-next-line no-undef
    fetch(url);
  };

  return (
    <div className="PlayerPage">
      <br />
      <div className="columns">
        <div className="column col-6 col-md-8 col-sm-10 col-xs-12 col-mx-auto text-center">
          <h3>
            {`Player: ${playerDoc.id}`}
          </h3>
          <p>
            {`Round: ${roomDoc.data().round} / ${roomDoc.data().numRounds}`}
          </p>
          {roomDoc.data().roomState === 'PLAYING_WRITING' && (
            <div>
              {playerDoc.data().state === 'SUBMITTED' && playerDoc.data().playerUid === roomDoc.data().ownerUid && (
                <div>
                  <Button
                    onClick={nextState}
                    className="btn btn-primary btn-block"
                    text={`Progress to ${roomDoc.data().roomState === 'PLAYING_WRITING' ? 'drawing' : 'writing'}`}
                    disableDuration={5000}
                  />
                  <br />
                </div>
              )}
              <p>
                {roomDoc.data().round === 1
                  ? 'Choose a word or phrase for others to draw.'
                  : `What could ${storyPreviousRound.name}'s drawing be?`}
              </p>
              {roomDoc.data().round !== 1 && (
                <div>
                  <Sketchpad
                    isInteractive={false}
                    analytics={analytics}
                    addPoint={null}
                    initialPoints={storyPreviousRound.points}
                  />
                  <br />
                </div>
              )}
              {playerDoc.data().state === 'INPUTTING' && (
                <div>
                  <Input
                    shouldAutoFocus
                    placeholder="Prompt"
                    maxLength="100"
                    ref={inputPromptRef}
                    onSubmitFn={() => {
                      submitButtonGuessFieldRef.current.click();
                    }}
                  />
                  <Button
                    onClick={() => {
                      submitWriting(inputPromptRef.current.value);
                    }}
                    className="btn btn-primary btn-block"
                    ref={submitButtonGuessFieldRef}
                    text="Submit"
                  />
                </div>
              )}
              {playerDoc.data().state === 'SUBMITTED' && (
                <div>
                  <p>
                    {roomDoc.data().round === 1 ? 'Your prompt: ' : 'Your guess: '}
                    <strong>{playerDoc.data().prompt}</strong>
                  </p>
                  <Table
                    colNames={['Participant', 'Submitted']}
                    rows={playerDocs && playerDocs.docs
                      .sort((a, b) => a.id.toUpperCase() - b.id.toUpperCase())
                      .map((doc) => ({
                        key: doc.id,
                        columns: [
                          doc.id,
                          doc.data().state === 'SUBMITTED' ? '✅' : '❌',
                        ],
                      }))}
                  />
                </div>
              )}
            </div>
          )}
          {roomDoc.data().roomState === 'PLAYING_DRAWING' && (
            <div>
              {playerDoc.data().state === 'SUBMITTED' && (
                <div>
                  {playerDoc.data().playerUid === roomDoc.data().ownerUid && (
                    <div>
                      <Button
                        onClick={nextState}
                        className="btn btn-primary btn-block"
                        text={`Progress to ${roomDoc.data().roomState === 'PLAYING_WRITING' ? 'drawing' : 'writing'}`}
                        disableDuration={5000}
                      />
                      <br />
                    </div>
                  )}
                  <Table
                    colNames={['Participant', 'Submitted']}
                    rows={playerDocs && playerDocs.docs
                      .sort((a, b) => a.id.toUpperCase() - b.id.toUpperCase())
                      .map((doc) => ({
                        key: doc.id,
                        columns: [
                          doc.id,
                          doc.data().state === 'SUBMITTED' ? '✅' : '❌',
                        ],
                      }))}
                  />
                  <br />
                  <p>Your Drawing:</p>
                </div>
              )}
              <p>
                {`${storyPreviousRound.name} wants you to draw:`}
              </p>
              <h3>{storyPreviousRound.prompt}</h3>
              <Sketchpad
                isInteractive={playerDoc.data().state === 'INPUTTING'}
                analytics={analytics}
                clearTime={playerDoc.data().state === 'INPUTTING' ? clearTime : null}
                addPoint={playerDoc.data().state === 'INPUTTING' ? addPoint : null}
                initialPoints={playerDoc.data().state === 'INPUTTING' ? [] : playerDoc.data().points}
              />
              <br />
              {playerDoc.data().state === 'INPUTTING' && (
                <div>
                  {/* eslint-disable-next-line no-undef */}
                  {window.matchMedia('(max-width: 800px)').matches ? (
                    <div>
                      <Button onClick={clearDrawing} className="btn btn-primary btn-action s-circle btn-lg" style={{ position: 'fixed', bottom: '20px', left: '20px' }} text={(<i className="icon icon-delete" />)} />
                      <Button onClick={submitDrawing} className="btn btn-primary btn-action s-circle btn-lg" style={{ position: 'fixed', bottom: '20px', right: '20px' }} text={(<i className="far fa-paper-plane" />)} />
                    </div>
                  ) : (
                    <div className="btn-group btn-group-block">
                      <Button onClick={clearDrawing} className="btn" text={<i className="icon icon-delete" style={{ marginLeft: '40px', marginRight: '40px' }} />} />
                      <Button onClick={submitDrawing} className="btn" text={<i className="far fa-paper-plane" style={{ marginLeft: '40px', marginRight: '40px' }} />} />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
