import React from 'react';

export default ({
  roomDoc,
  playerDoc,
}) => {
  const renderPlayerName = (name, isMe = false) => (isMe ? <strong>{name}</strong> : name);
  const { questions } = roomDoc.data();
  const questionsByConsensus = {};
  questions
    .filter((q) => q.consensus && q.consensus !== 'None')
    .forEach((q) => {
      if (!questionsByConsensus[q.consensus]) {
        questionsByConsensus[q.consensus] = [];
      }
      questionsByConsensus[q.consensus].push(q.question);
    });
  return (
    <table className="table table-hover">
      <thead>
        <tr>
          <th className="text-center">Consensus</th>
          <th className="text-center">Statement</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(questionsByConsensus)
          .sort()
          .map((playerName) => {
            const isMe = playerDoc.id === playerName;
            return questionsByConsensus[playerName].map((q) => (
              <tr
                key={q}
              >
                <td className="text-center">{renderPlayerName(playerName, isMe)}</td>
                <td className="text-center">{q}</td>
              </tr>
            ));
          })}
      </tbody>
    </table>
  );
};
