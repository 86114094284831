/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';

import Button from './utils/Button';
import Header from './utils/Header';

import ErrorPage, {
  ERROR_TYPE_TO_MESSAGE,
} from './utils/ErrorPage';
import {
  ROOM_DOES_NOT_EXIST,
  MUST_SPECIFY_ROOMID,
} from './common/errors';

export default ({
  firestore,
}) => {
  const history = useHistory();

  const [error, setError] = useState(null);
  const [inputName, setInputName] = useState('');
  const [inputNameError, setInputNameError] = useState(null);
  const [inputRoomId, setInputRoomId] = useState('');
  const [inputRoomIdError, setInputRoomIdError] = useState(null);

  const submitButtonFieldRef = React.createRef();

  const match = useRouteMatch();

  // roomId
  const [roomId, setRoomId] = useState(null);
  useEffect(() => {
    if (!match
      || roomId // roomId already set
    ) {
      return;
    }
    setRoomId(match.params.roomId);
  }, [match, roomId]);

  const playGame = async () => {
    const actualRoomId = roomId || inputRoomId;
    if (actualRoomId) {
      // join game
      if (!actualRoomId) {
        setInputRoomIdError(MUST_SPECIFY_ROOMID);
        return;
      }
      const roomRef = firestore.collection('rooms').doc(actualRoomId);
      const roomDoc = await roomRef
        .get();
      if (!roomDoc || !roomDoc.exists) {
        setError(new Error(ROOM_DOES_NOT_EXIST));
        return;
      }
      // if joining room, use the room's gameSelection
      history.push(`/${roomDoc.data().gameSelection}/${actualRoomId}/player/${inputName}`);
    }
  };

  if (error) {
    return <ErrorPage error={error} />;
  }
  return (
    <div className="Home">
      <Header />
      <div className="columns">
        <div className="column col-auto col-mx-auto">
          <h2>Join a Game</h2>
        </div>
      </div>
      <div className="columns">
        <div className="column col-6 col-mx-auto">
          <div className="form-group">
            <div className="columns">
              <div className="column col-auto col-mr-auto">
                <label
                  className="form-label"
                  htmlFor="inputName"
                >
                  Name
                </label>
              </div>
            </div>
            <div className="columns">
              <div className="column col-mx-auto">
                <input
                  className={inputNameError ? 'form-input is-error' : 'form-input'}
                  autoFocus
                  type="text"
                  id="inputName"
                  placeholder="Name (Optional)"
                  maxLength="26"
                  value={inputName}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter' && submitButtonFieldRef) {
                      submitButtonFieldRef.current.click();
                    }
                  }}
                  onChange={(event) => {
                    setInputName(event.target.value);
                    setInputNameError(null);
                  }}
                />
                {inputNameError && (
                  <p className="form-input-hint">
                    {ERROR_TYPE_TO_MESSAGE[inputNameError].error
                    + ERROR_TYPE_TO_MESSAGE[inputNameError].message}
                  </p>
                )}
              </div>
            </div>
            <div className="columns">
              <div className="column col-auto col-mr-auto">
                <label
                  className="form-label"
                  htmlFor="inputRoomId"
                >
                  Room
                </label>
              </div>
            </div>
            <div className="columns">
              <div className="column col-mx-auto">
                <input
                  // eslint-disable-next-line no-nested-ternary
                  className={inputRoomIdError ? 'form-input is-error' : roomId ? 'form-input disabled' : 'form-input'}
                  type="text"
                  id="inputRoomId"
                  placeholder="Room ID (4 letters)"
                  maxLength="4"
                  value={roomId || inputRoomId}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter' && submitButtonFieldRef) {
                      submitButtonFieldRef.current.click();
                    }
                  }}
                  disabled={roomId === null}
                  onChange={(event) => {
                    setInputRoomId(event.target.value);
                    setInputRoomIdError(null);
                  }}
                />
                {inputRoomIdError && (
                  <p className="form-input-hint">
                    {ERROR_TYPE_TO_MESSAGE[inputRoomIdError].error
                    + ERROR_TYPE_TO_MESSAGE[inputRoomIdError].message}
                  </p>
                )}
              </div>
            </div>
            <br />
            <div className="columns">
              <div className="column col-mx-auto">
                <Button
                  onClick={playGame}
                  className="btn btn-primary btn-block"
                  text="Join"
                  ref={submitButtonFieldRef}
                  disableDuration={5000}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};
