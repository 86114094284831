import React, { useState, useEffect } from 'react';

export default React.forwardRef((
  {
    disableDuration,
    onClick,
    className,
    style,
    tooltip,
    text,
  }, forwardRef,
) => {
  const [buttonEnabled, setButtonEnabled] = useState(true);

  useEffect(() => {
    if (buttonEnabled) {
      return () => {};
    }
    const timer = setTimeout(() => {
      setButtonEnabled(true);
    }, disableDuration || 500);
    return () => clearTimeout(timer);
  }, [buttonEnabled, disableDuration]);

  return (
    <button
      type="button"
      onClick={(event) => {
        setButtonEnabled(false);
        onClick(event);
      }}
      ref={forwardRef}
      data-tooltip={tooltip}
      style={style || {}}
      className={className
                + (buttonEnabled ? '' : ' loading')
                + (tooltip ? ' tooltip tooltip-bottom' : '')}
    >
      {text}
    </button>
  );
});
