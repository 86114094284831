import React, { useEffect, useState } from 'react';
import { answerValidatorString, submitAnswer } from '../common/player';
import { getAnswer, getQuestion, getType } from './Common';
import Button from '../utils/Button';
import Table from '../utils/Table';

const checkAnswer = (expectedRaw, upperRaw) => {
  const expected = (expectedRaw || '').toLowerCase().replace(/[^a-zA-Z0-9 ]/g, '');
  const actual = (upperRaw || '').toLowerCase().replace(/[^a-zA-Z0-9 ]/g, '');
  if (expected === actual) {
    return true;
  }
  if (actual.length === 0) {
    return false;
  }
  // check without spacing and punctuation
  const actualWithoutSpaces = actual.replace(/\s/g, '');
  const expectedWithoutSpaces = expected.replace(/\s/g, '');
  if (actualWithoutSpaces.length >= Math.min(expectedWithoutSpaces.length, 3)
      && expectedWithoutSpaces.includes(actualWithoutSpaces)) {
    return true;
  }
  return false;
};

export default ({
  firestore,
  roomDoc,
  playerDoc,
  playerDocs,
  gameSelection,
  setError,
}) => {
  const [inputAnswer, setInputAnswer] = useState('');
  const submitButtonFieldRef = React.createRef();

  // reset inputAnswer on questionState change
  const [prevQuestionState, setPrevQuestionState] = useState('');
  useEffect(() => {
    const newQuestionState = roomDoc.data().questionState;
    if (newQuestionState === prevQuestionState) {
      return;
    }
    if (getType(roomDoc) === 'truefalse') {
      setInputAnswer('true'); // default to true
    } else {
      setInputAnswer('');
    }
    setPrevQuestionState(newQuestionState);
  }, [roomDoc, prevQuestionState]);

  const checkAnswers = () => {
    const url = new URL(`https://us-central1-trivia-03212020.cloudfunctions.net/${gameSelection.toLowerCase()}-playingAnswer`);
    url.searchParams.append('roomId', roomDoc.id);
    // eslint-disable-next-line no-undef
    fetch(url);
  };

  const nextQuestion = () => {
    const url = new URL(`https://us-central1-trivia-03212020.cloudfunctions.net/${gameSelection.toLowerCase()}-playingQuestion`);
    url.searchParams.append('roomId', roomDoc.id);
    // eslint-disable-next-line no-undef
    fetch(url);
  };

  const expectedAnswer = roomDoc ? getAnswer(roomDoc) : '';
  return (
    <div className="PlayerPage">
      <br />
      <div className="columns">
        <div className="column col-6 col-md-8 col-sm-10 col-xs-12 col-mx-auto text-center">
          <h3>
            {`Player: ${playerDoc.id}`}
          </h3>
          <p>
            {`Question ${roomDoc.data().round}/${roomDoc.data().numRounds}`}
          </p>
          <h4>{getQuestion(roomDoc)}</h4>
          {roomDoc.data().questionState === 'WAITING' && (
            <div>
              {playerDoc.data().state === 'INPUTTING' && (
                <div>
                  {playerDoc.data().playerUid === roomDoc.data().ownerUid && (
                    <div>
                      <Button
                        onClick={nextQuestion}
                        className="btn btn-block"
                        text="Skip Question"
                      />
                      <br />
                    </div>
                  )}
                  <div className="columns">
                    <div className="column col-mx-auto">
                      {getType(roomDoc) === 'text' && (
                        <input
                          className="form-input"
                          // eslint-disable-next-line jsx-a11y/no-autofocus
                          autoFocus
                          type="text"
                          placeholder="Answer"
                          value={inputAnswer}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter' && submitButtonFieldRef) {
                              submitButtonFieldRef.current.click();
                            }
                          }}
                          onChange={(event) => {
                            setInputAnswer(event.target.value);
                          }}
                        />
                      )}
                      {getType(roomDoc) === 'truefalse' && (
                        <select
                          className="form-select"
                          // eslint-disable-next-line jsx-a11y/no-autofocus
                          autoFocus
                          style={{ color: 'black' }}
                          value={inputAnswer}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter' && submitButtonFieldRef) {
                              submitButtonFieldRef.current.click();
                            }
                          }}
                          onChange={(event) => {
                            setInputAnswer(event.target.value);
                          }}
                        >
                          <option value="true">True</option>
                          <option value="false">False</option>
                        </select>
                      )}
                      <br />
                      <Button
                        onClick={() => {
                          try {
                            if (inputAnswer.length === 0 && getType(roomDoc) === 'truefalse') {
                              setInputAnswer('true');
                            }
                            submitAnswer(
                              firestore,
                              roomDoc.id,
                              playerDoc.id,
                              inputAnswer,
                              answerValidatorString,
                            );
                          } catch (e) {
                            setError(e);
                          }
                        }}
                        className="btn btn-primary btn-block"
                        ref={submitButtonFieldRef}
                        text="Submit"
                      />
                      <br />
                    </div>
                  </div>
                </div>
              )}
              {playerDoc.data().state === 'SUBMITTED' && (
                <div>
                  <p>
                    Your Answer:&nbsp;
                    <strong>{playerDoc.data().answer || '...'}</strong>
                  </p>
                  <p>Waiting for other participants to submit their answers.</p>
                  {playerDoc.data().playerUid === roomDoc.data().ownerUid && (
                    <div>
                      <Button
                        onClick={checkAnswers}
                        className="btn btn-primary btn-block"
                        text="Check Answers"
                        disableDuration={5000}
                      />
                      <br />
                    </div>
                  )}
                  <Table
                    colNames={['Participant', 'Score', 'Submitted']}
                    rows={playerDocs.docs.map((doc) => ({
                      key: doc.id,
                      columns: [
                        doc.id,
                        doc.data().score,
                        doc.data().state === 'SUBMITTED' ? '✅' : '❌',
                      ],
                    }))}
                  />
                </div>
              )}
            </div>
          )}
          {roomDoc.data().questionState === 'INTERMISSION' && (
            <div>
              <br />
              <p>
                Your Answer:&nbsp;
                <strong>{playerDoc.data().answer || '...'}</strong>
              </p>
              <p>
                Accepted Answer:&nbsp;
                <strong>{getAnswer(roomDoc)}</strong>
              </p>
              {playerDoc.data().playerUid === roomDoc.data().ownerUid && (
                <div>
                  <Button
                    onClick={nextQuestion}
                    className="btn btn-primary btn-block"
                    text="Next Question"
                    disableDuration={5000}
                  />
                  <br />
                </div>
              )}
              <br />
              <Table
                colNames={['Participant', 'Score', 'Answer']}
                rows={playerDocs.docs.map((doc) => ({
                  key: doc.id,
                  columns: [
                    doc.id,
                    doc.data().score,
                    `${doc.data().answer || '...'}${checkAnswer(expectedAnswer, doc.data().answer) ? ' ✅' : ''}`,
                  ],
                }))}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
