import React from 'react';
import Button from '../utils/Button';
import Table from '../utils/Table';
import SharableLink from '../utils/SharableLink';

export default ({
  roomDoc,
  playerDoc,
  playerDocs,
  gameSelection,
}) => {
  const startGame = () => {
    const url = new URL(`https://us-central1-trivia-03212020.cloudfunctions.net/${gameSelection.toLowerCase()}-startGame`);
    url.searchParams.append('roomId', roomDoc.id);
    // eslint-disable-next-line no-undef
    fetch(url);
  };

  return (
    <div className="PlayerPage">
      <br />
      <div className="columns">
        <div className="column col-6 col-md-8 col-sm-10 col-xs-12 col-mx-auto text-center">
          <h1>{gameSelection}</h1>
          <h2>
            {`Room: ${roomDoc.id}`}
          </h2>
          <h3>
            {`Player: ${playerDoc.id}`}
          </h3>
          <p>Waiting for participants...</p>
          <SharableLink roomId={roomDoc.id} />
          <br />
          {roomDoc.data().ownerUid === playerDoc.data().playerUid && (
            <div>
              <Button
                onClick={startGame}
                className="btn btn-primary btn-block"
                text="Start (Everyone's In)"
                disableDuration={5000}
              />
              <br />
            </div>
          )}
          <Table
            colNames={['Participant', 'Ready']}
            rows={playerDocs.docs
              .sort((a, b) => a.id.toUpperCase() - b.id.toUpperCase())
              .map((doc) => ({
                key: doc.id,
                columns: [
                  doc.id,
                  '✅',
                ],
              }))}
          />
        </div>
      </div>
    </div>
  );
};
