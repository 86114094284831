import React from 'react';

export default ({
  playerDocs,
}) => {
  if (!playerDocs) {
    return <div />;
  }
  const answersByConsensus = {};
  playerDocs.docs
    .filter((doc) => doc.data().answer)
    .forEach((doc) => {
      const answer = doc.data().answer.toLowerCase();
      if (!answersByConsensus[answer]) {
        answersByConsensus[answer] = [];
      }
      answersByConsensus[answer].push({
        name: doc.id,
        score: doc.data().score,
        answer: doc.data().answer,
      });
    });
  return (
    <table className="table table-hover">
      <thead>
        <tr>
          <th className="text-center">Players</th>
          <th className="text-center">Score</th>
          <th className="text-center">Answer</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(answersByConsensus)
          .sort()
          .map((answer) => answersByConsensus[answer].map((player) => {
            const playerObj = answersByConsensus[answer].filter((p) => p.name === player.name)[0];
            return (
              <tr
                key={playerObj.name}
              >
                <td className="text-center">{playerObj.name}</td>
                <td className="text-center">{playerObj.score}</td>
                <td className="text-center">{answersByConsensus[answer].length > 1 ? `${playerObj.answer} ✅` : playerObj.answer}</td>
              </tr>
            );
          }))}
      </tbody>
    </table>
  );
};
