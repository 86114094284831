import React from 'react';

export default ({
  playerDocs,
}) => {
  if (!playerDocs) {
    return <div />;
  }
  return (
    <table className="table table-hover">
      <thead>
        <tr>
          <th className="text-center">Players</th>
          <th className="text-center">Score</th>
          <th className="text-center">Answer</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(playerDocs.docs)
          .sort((a, b) => b[1].data().score - a[1].data().score)
          .map(([, doc]) => (
            <tr
              key={doc.id}
            >
              <td className="text-center">{doc.id}</td>
              <td className="text-center">{doc.data().score}</td>
              <td className="text-center">{doc.data().answer === 'HAVE' ? 'Has ✅' : 'Has Not ❌'}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};
