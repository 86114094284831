import React, { useState } from 'react';

export default React.forwardRef(({
  error = '',
  shouldAutoFocus,
  placeholder,
  maxLength = 20,
  onSubmitFn,
}, inputRef) => {
  const [e, setError] = useState(error);
  const [value, setValue] = useState('');
  return (
    <input
      ref={inputRef}
      className={e ? 'form-input is-error' : 'form-input'}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={shouldAutoFocus}
      type="text"
      placeholder={placeholder}
      maxLength={maxLength}
      value={value}
      onKeyPress={(event) => {
        if (event.key === 'Enter') {
          onSubmitFn(value);
        }
      }}
      onChange={(event) => {
        setValue(event.target.value);
        setError(null);
      }}
    />
  );
});
