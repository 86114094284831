import React, { useState, useEffect } from 'react';

import {
  PAGE_DOES_NOT_EXIST,
  ROOM_DOES_NOT_EXIST,
  ROOM_WRONG_GAME_TYPE,
  ROOM_ALREADY_PLAYING,
  ROOM_ID_INVALID,
  BROWSER_NOT_SUPPORTED,
  ROOM_NOT_OWNED_BY_USER,
  PLAYER_UID_DIFFERS,
  MUST_SPECIFY_ROOMID,
  INVALID_NUM_ROUNDS,
  SUBMIT_FAILURE_ROOM_NOT_PLAYING,
  SUBMIT_FAILURE_ROOM_QUESTION_NOT_WAITING,
  SUBMIT_FAILURE_PLAYER_NOT_IN_ROOM,
  SUBMIT_FAILURE_ALREADY_SUBMITTED,
} from '../common/errors';

export const ERROR_TYPE_TO_MESSAGE = {};
ERROR_TYPE_TO_MESSAGE[PAGE_DOES_NOT_EXIST] = {
  error: 'Page does not exist. ',
  message: 'Check your URL and try again.',
};
ERROR_TYPE_TO_MESSAGE[ROOM_DOES_NOT_EXIST] = {
  error: 'The room does not exist. ',
  message: 'Check your Room ID and try again.',
};
ERROR_TYPE_TO_MESSAGE[ROOM_WRONG_GAME_TYPE] = {
  error: "The selected game type does not match the room's game type. ",
  message: 'Check your game type and try again.',
};
ERROR_TYPE_TO_MESSAGE[ROOM_ALREADY_PLAYING] = {
  error: 'The room has already started the game. ',
  message: 'Wait until the next round to join.',
};
ERROR_TYPE_TO_MESSAGE[ROOM_ID_INVALID] = {
  error: 'The room id specified is invalid. ',
  message: 'Check the room id and try again.',
};
ERROR_TYPE_TO_MESSAGE[BROWSER_NOT_SUPPORTED] = {
  error: 'The browser you are using is not supported for this game type. ',
  message: 'Use a different browser (Chrome) and try again.',
};
ERROR_TYPE_TO_MESSAGE[ROOM_NOT_OWNED_BY_USER] = {
  error: 'The room you have entered is owned by another user. ',
  message: 'Create a new room to play.',
};
ERROR_TYPE_TO_MESSAGE[PLAYER_UID_DIFFERS] = {
  error: 'The player you are trying to enter as has different credentials. ',
  message: 'Play as a different user.',
};
ERROR_TYPE_TO_MESSAGE[MUST_SPECIFY_ROOMID] = {
  error: 'Room ID not specified. ',
  message: 'Add a Room ID and try again.',
};
ERROR_TYPE_TO_MESSAGE[SUBMIT_FAILURE_ROOM_NOT_PLAYING] = {
  error: 'SUBMIT_FAILURE_ROOM_NOT_PLAYING',
  message: 'Cannot submit answer when the room is not playing.',
};
ERROR_TYPE_TO_MESSAGE[SUBMIT_FAILURE_ROOM_QUESTION_NOT_WAITING] = {
  error: 'SUBMIT_FAILURE_ROOM_QUESTION_NOT_WAITING',
  message: 'Cannot submit answer when the room is not in the waiting questionState.',
};
ERROR_TYPE_TO_MESSAGE[SUBMIT_FAILURE_PLAYER_NOT_IN_ROOM] = {
  error: 'SUBMIT_FAILURE_PLAYER_NOT_IN_ROOM',
  message: 'Cannot submit answer when not participating in the room.',
};
ERROR_TYPE_TO_MESSAGE[SUBMIT_FAILURE_ALREADY_SUBMITTED] = {
  error: 'SUBMIT_FAILURE_ALREADY_SUBMITTED',
  message: 'Cannot re-submit answer after a submission.',
};
ERROR_TYPE_TO_MESSAGE[INVALID_NUM_ROUNDS] = {
  error: 'INVALID_NUM_ROUNDS',
  message: 'Invalid number of rounds selected.',
};

/**
 * props = {
 *   errorType,
 * }
 */
const ErrorPage = ({ analytics, error }) => {
  const [hasLogged, setHasLogged] = useState(false);
  useEffect(() => {
    if (!analytics
      || !error
      || hasLogged) {
      return;
    }
    analytics.logEvent('error', {
      type: error.message,
    });
    setHasLogged(true);
  }, [analytics, error, hasLogged]);

  if (!error) {
    return (<div />);
  }
  if (typeof error === 'string') {
    return (
      <div className="Error">
        <div className="columns">
          <div className="column col-auto col-mx-auto text-center">
            <h1>{`Unparsable error: ${error}`}</h1>
          </div>
        </div>
      </div>
    );
  }
  const decodedError = ERROR_TYPE_TO_MESSAGE[error.message];
  if (!decodedError) {
    console.error(error);
    return (
      <div className="Error">
        <div className="columns">
          <div className="column col-auto col-mx-auto text-center">
            <h1>{`Unknown error: ${JSON.stringify(error)}`}</h1>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="Error">
      <div className="columns">
        <div className="column col-auto col-mx-auto text-center">
          <h1>{error.message}</h1>
          <p>{`${decodedError.error} ${decodedError.message}`}</p>
        </div>
      </div>
    </div>
  );
};

export const ErrorNotFoundPage = () => (
  <ErrorPage error={new Error(PAGE_DOES_NOT_EXIST)} />
);

export default ErrorPage;
