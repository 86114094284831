import React, { useState } from 'react';
import { INVALID_NUM_ROUNDS } from '../common/errors';
import Button from '../utils/Button';
import InputCategory from '../utils/InputCategory';
import InputRounds from '../utils/InputRounds';
import Table from '../utils/Table';
import SharableLink from '../utils/SharableLink';

const fetchCategories = () => [
  'All',
];

export default ({
  roomDoc,
  playerDoc,
  playerDocs,
  gameSelection,
  categories = fetchCategories(),
}) => {
  const [numRounds, setNumRounds] = useState('10');
  const [numRoundsError, setNumRoundsError] = useState(null);
  const [inputCategory, setInputCategory] = useState('All');

  const startGame = () => {
    if (!/^\+?\d+$/.test(numRounds)
      || parseInt(numRounds, 10) > 50
      || parseInt(numRounds, 10) < 1) {
      setNumRoundsError(INVALID_NUM_ROUNDS);
      return;
    }
    const url = new URL(`https://us-central1-trivia-03212020.cloudfunctions.net/${gameSelection.toLowerCase()}-startGame`);
    url.searchParams.append('roomId', roomDoc.id);
    url.searchParams.append('inputCategory', inputCategory);
    url.searchParams.append('rounds', numRounds);
    // eslint-disable-next-line no-undef
    fetch(url);
  };

  return (
    <div className="PlayerPage">
      <br />
      <div className="columns">
        <div className="column col-6 col-md-8 col-sm-10 col-xs-12 col-mx-auto text-center">
          <h1>{gameSelection}</h1>
          <h2>
            {`Room: ${roomDoc.id}`}
          </h2>
          <h3>
            {`Player: ${playerDoc.id}`}
          </h3>
          <p>Waiting for participants...</p>
          <SharableLink roomId={roomDoc.id} />
          <br />
          {roomDoc.data().ownerUid === playerDoc.data().playerUid && (
            <div>
              <InputCategory
                inputCategory={inputCategory}
                setInputCategory={setInputCategory}
                categories={categories}
              />
              <br />
              <InputRounds
                numRounds={numRounds}
                setNumRounds={setNumRounds}
                numRoundsError={numRoundsError}
                setNumRoundsError={setNumRoundsError}
              />
              <br />
              <Button
                onClick={startGame}
                className="btn btn-primary btn-block"
                text="Start (Everyone's In)"
                disableDuration={10000}
              />
            </div>
          )}
          {playerDocs && (
            <Table
              colNames={['Participants', 'Ready']}
              rows={playerDocs.docs.map((doc) => ({
                key: doc.id,
                columns: [
                  doc.id,
                  '✅',
                ],
              }))}
            />
          )}
        </div>
      </div>
    </div>
  );
};
