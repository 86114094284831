import React from 'react';

export default () => (
  <div className="Header">
    <header className="navbar">
      <section className="navbar-section">
        <a href="/" className="navbar-brand mr-2">Secluded Games</a>
      </section>
      <section className="navbar-section">
        <a href="/start" className="btn btn-link">Start Game</a>
        /
        <a href="/join" className="btn btn-link">Join Game</a>
      </section>
    </header>
  </div>
);
