import React, { useState, useEffect } from 'react';

import Sketchpad from './Sketchpad';

export default ({
  firestore,
  analytics,
  storyIndex,
  roomId,
}) => {
  const [story, setStory] = useState(null);

  // load story
  useEffect(() => {
    const fetchData = async () => {
      setStory(await firestore.collection('rooms').doc(roomId)
        .collection('stories').doc(`${storyIndex}`)
        .get());
    };
    fetchData();
  }, [firestore, roomId, storyIndex]);

  return (
    <div className="Story">
      <br />
      {story
      && story.exists
      && Object
        .keys(story.data())
        .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
        .map((round) => {
          const storyData = story.data();
          return (
            <div className="storyRound" key={`${round}`}>
              {parseInt(round, 10) % 2 === 1
                ? (
                  <div>
                    <span>{`${storyData[`${round}`].name}${round === 1 ? "'s story" : ' guessed'}`}</span>
                    <h3>{storyData[`${round}`].prompt}</h3>
                  </div>
                )
                : (
                  <div>
                    <span>{`${storyData[`${round}`].name} drew`}</span>
                    <Sketchpad
                      isInteractive={false}
                      addPoint={null}
                      analytics={analytics}
                      initTime={Date.now()}
                      initialPoints={storyData[`${round}`].points}
                      downloadButton
                    />
                  </div>
                )}
            </div>
          );
        })}
    </div>
  );
};
