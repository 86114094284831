import React, { createContext } from 'react';
import app from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/auth';

const FirebaseContext = createContext(null);
export { FirebaseContext };

export default ({ children }) => {
  if (!app.apps.length) {
    app.initializeApp({
      apiKey: 'AIzaSyAH3e4L4VYya9IH2ACHF2j0ZwUMKLMwWdQ',
      authDomain: 'trivia-03212020.firebaseapp.com',
      databaseURL: 'https://trivia-03212020.firebaseio.com',
      projectId: 'trivia-03212020',
      storageBucket: 'trivia-03212020.appspot.com',
      messagingSenderId: '67881343858',
      appId: '1:67881343858:web:dc6490d630e5cbe4ae9beb',
      measurementId: 'G-NJR3YGKY40',
    });
  }
  return (
    <FirebaseContext.Provider value={app}>
      { children }
    </FirebaseContext.Provider>
  );
};
