import React from 'react';
import Button from '../utils/Button';
import Storybook from './Storybook';

export default ({
  firestore,
  analytics,
  roomDoc,
  playerDoc,
  gameSelection,
}) => {
  const newGame = () => {
    const url = new URL(`https://us-central1-trivia-03212020.cloudfunctions.net/${gameSelection.toLowerCase()}-newGame`);
    url.searchParams.append('roomId', roomDoc.id);
    // eslint-disable-next-line no-undef
    fetch(url);
  };

  return (
    <div className="PlayerPage">
      <br />
      <div className="columns">
        <div className="column col-6 col-md-8 col-sm-10 col-xs-12 col-mx-auto text-center">
          <h1>{gameSelection}</h1>
          <h2>
            {`Room: ${roomDoc.id}`}
          </h2>
          <h3>
            {`Player: ${playerDoc.id}`}
          </h3>
          <p>Game over</p>
          {playerDoc.data().playerUid === roomDoc.data().ownerUid && (
            <div>
              <Button
                onClick={newGame}
                className="btn btn-primary btn-block"
                text="New Game"
                disableDuration={5000}
              />
              <br />
            </div>
          )}
          <Storybook
            firestore={firestore}
            analytics={analytics}
            storyIndex={playerDoc.data().originalStoryIndex}
            roomId={roomDoc.id}
          />
        </div>
      </div>
    </div>
  );
};
