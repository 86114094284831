import React, { useContext, useEffect, useState } from 'react';
import {
  BrowserRouter, Switch, Route, useRouteMatch, useHistory,
} from 'react-router-dom';
import fetch from 'isomorphic-fetch';

import { FirebaseContext } from './utils/firebase';
import 'firebase/firestore';

import HomePage from './HomePage';
import JoinPage from './JoinPage';
import StartPage from './StartPage';

import { ErrorNotFoundPage } from './utils/ErrorPage';

import TelestrationsPlayerPage from './telestrations/PlayerPage';
import TriviaPlayerPage from './trivia/PlayerPage';
import RobotKaraokePlayerPage from './robotkaraoke/PlayerPage';
import ConsensusPlayerPage from './consensus/PlayerPage';
import InsyncPlayerPage from './insync/PlayerPage';
import NeverHaveIEverPlayerPage from './neverhaveiever/PlayerPage';

const generateRoomId = () => {
  const min = 'A'.charCodeAt(0);
  const max = 'Z'.charCodeAt(0);
  const rand = () => Math.random() * (max - min) + min;
  return String.fromCharCode(rand())
       + String.fromCharCode(rand())
       + String.fromCharCode(rand())
       + String.fromCharCode(rand());
};

export const validateRoomId = (roomId) => roomId.length === 4;

const adjectives = [
  'hungry',
  'thirsty',
  'large',
  'tiny',
  'huge',
  'small',
  'exotic',
  'plain',
  'red',
  'blue',
  'black',
  'white',
  'green',
];
const nouns = [
  'rat',
  'cow',
  'tiger',
  'bunny',
  'dragon',
  'snake',
  'horse',
  'goat',
  'monkey',
  'rooster',
  'dog',
  'pig',
];
const generatePlayerName = () => {
  const adjective = adjectives[Math.floor(Math.random() * (adjectives.length - 1))];
  const noun = nouns[Math.floor(Math.random() * (nouns.length - 1))];
  return `${adjective}-${noun}`;
};

const InitPlayerPage = ({
  firestore,
  auth,
  gameSelection,
}) => {
  const match = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (!auth.user) {
      return;
    }
    const initRoom = async () => {
      const {
        roomId,
        name,
      } = match.params;
      const playerName = name || generatePlayerName();
      let actualRoomId = roomId;
      if (!actualRoomId) {
        // init a new room
        actualRoomId = generateRoomId();
        console.log(`${actualRoomId}: initializing`);
        switch (gameSelection) {
          case 'consensus':
          case 'telestrations':
          case 'trivia':
          case 'karaoke':
          case 'insync':
          case 'neverhaveiever':
            // eslint-disable-next-line no-case-declarations
            const url = new URL('https://us-central1-trivia-03212020.cloudfunctions.net/initGame');
            url.searchParams.append('roomId', actualRoomId);
            url.searchParams.append('ownerUid', auth.user.uid);
            url.searchParams.append('gameSelection', gameSelection);
            await fetch(url);
            break;
          default:
            console.error(`Invalid gameSelection: ${gameSelection}`);
            throw new Error(`Invalid gameSelection: ${gameSelection}`);
        }
      }
      history.push(`/${gameSelection}/${actualRoomId}/player/${playerName}`);
    };
    initRoom();
  }, [firestore, auth, gameSelection, match, history]);
  return null;
};

export default () => {
  const firebase = useContext(FirebaseContext);

  // auth listener
  const [auth, setAuth] = useState({
    finished: false,
    user: null,
  });
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setAuth({
        finished: true,
        user,
      });
    });
    return () => { unsubscribe(); };
  }, [firebase]);
  useEffect(() => {
    if (auth.finished
      && auth.user // login success
    ) {
      return;
    }
    firebase.auth().signInAnonymously();
  }, [firebase, auth]);

  return (
    <BrowserRouter>
      <div className="columns">
        <div className="column col-12 col-mx-auto">
          <Switch>
            {/* never have i ever */}
            <Route exact path="/neverhaveiever/:roomId/player/:name">
              <NeverHaveIEverPlayerPage
                firestore={firebase.firestore()}
                auth={auth}
              />
            </Route>
            <Route
              exact
              path={[
                '/neverhaveiever/:roomId/player',
                '/neverhaveiever/player/:name',
                '/neverhaveiever/player',
                '/neverhaveiever',
              ]}
            >
              <InitPlayerPage
                firestore={firebase.firestore()}
                auth={auth}
                gameSelection="neverhaveiever"
              />
            </Route>

            {/* in sync */}
            <Route exact path="/insync/:roomId/player/:name">
              <InsyncPlayerPage
                firestore={firebase.firestore()}
                auth={auth}
              />
            </Route>
            <Route
              exact
              path={[
                '/insync/:roomId/player',
                '/insync/player/:name',
                '/insync/player',
                '/insync',
              ]}
            >
              <InitPlayerPage
                firestore={firebase.firestore()}
                auth={auth}
                gameSelection="insync"
              />
            </Route>

            {/* consensus */}
            <Route exact path="/consensus/:roomId/player/:name">
              <ConsensusPlayerPage
                firestore={firebase.firestore()}
                auth={auth}
              />
            </Route>
            <Route
              exact
              path={[
                '/consensus/:roomId/player',
                '/consensus/player/:name',
                '/consensus/player',
                '/consensus',
              ]}
            >
              <InitPlayerPage
                firestore={firebase.firestore()}
                auth={auth}
                gameSelection="consensus"
              />
            </Route>

            {/* robotkaraoke */}
            <Route exact path="/karaoke/:roomId/player/:name">
              <RobotKaraokePlayerPage
                firestore={firebase.firestore()}
                auth={auth}
              />
            </Route>
            <Route
              exact
              path={[
                '/karaoke/:roomId/player/',
                '/karaoke/player/:name',
                '/karaoke/player',
                '/karaoke',
              ]}
            >
              <InitPlayerPage
                firestore={firebase.firestore()}
                auth={auth}
                gameSelection="karaoke"
              />
            </Route>

            {/* trivia */}
            <Route exact path="/trivia/:roomId/player/:name">
              <TriviaPlayerPage
                firestore={firebase.firestore()}
                auth={auth}
              />
            </Route>
            <Route
              exact
              path={[
                '/trivia/:roomId/player',
                '/trivia/player/:name',
                '/trivia/player',
                '/trivia',
              ]}
            >
              <InitPlayerPage
                firestore={firebase.firestore()}
                auth={auth}
                gameSelection="trivia"
              />
            </Route>

            {/* telestrations */}
            <Route exact path="/telestrations/:roomId/player/:name">
              <TelestrationsPlayerPage
                firestore={firebase.firestore()}
                analytics={firebase.analytics()}
                auth={auth}
              />
            </Route>
            <Route
              exact
              path={[
                '/telestrations/:roomId/player/',
                '/telestrations/player/:name',
                '/telestrations/player',
                '/telestrations',
              ]}
            >
              <InitPlayerPage
                firestore={firebase.firestore()}
                auth={auth}
                gameSelection="telestrations"
              />
            </Route>

            {/* join */}
            <Route
              exact
              path={[
                '/join/:roomId',
                '/join',
              ]}
            >
              <JoinPage
                firestore={firebase.firestore()}
              />
            </Route>

            {/* start */}
            <Route exact path="/start">
              <StartPage />
            </Route>

            {/* home */}
            <Route exact path="/">
              <HomePage
                firestore={firebase.firestore()}
                auth={auth}
              />
            </Route>
            <Route component={ErrorNotFoundPage} />
          </Switch>
        </div>
      </div>
    </BrowserRouter>
  );
};
