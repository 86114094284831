import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';

import ErrorPage from '../utils/ErrorPage';
import {
  ROOM_DOES_NOT_EXIST,
} from '../common/errors';
import { joinRoom } from '../common/room';

import RoomWaiting from '../shared/RoomWaiting';
import RoomFinished from '../shared/RoomFinished';
import RoomPlaying from './RoomPlaying';

const GAME_SELECTION = 'Trivia';

export const checkAnswer = (expectedRaw, upperRaw) => {
  const numberMapping = {
    zero: '0',
    one: '1',
    two: '2',
    three: '3',
    four: '4',
    five: '5',
    six: '6',
    seven: '7',
    eight: '8',
    nine: '9',
    ten: '10',
    eleven: '11',
    twelve: '12',
    thirteen: '13',
    fourteen: '14',
    fifteen: '15',
    sixteen: '16',
    seventeen: '17',
    eighteen: '18',
    nineteen: '19',
    twenty: '20',
    'twenty-one': '21',
    'twenty one': '21',
  };
  const expected = (expectedRaw || '').toLowerCase().replace(/[^a-zA-Z0-9 ]/g, '');
  const actual = (upperRaw || '').toLowerCase().replace(/[^a-zA-Z0-9 ]/g, '');
  if (expected === actual) {
    return true;
  }
  // check without spacing and punctuation
  if (expected.replace(/\s/g, '') === actual.replace(/\s/g, '')) {
    return true;
  }
  // check if any single word matches
  const expectedArray = expected.split(' ');
  for (let i = 0; i < expectedArray.length; i += 1) {
    const e = expectedArray[i];
    if (expectedArray.length > 1 && (e === 'the' || e === 'a' || e === 'an')) {
      // if it's more than a single word, skip articles
      // eslint-disable-next-line no-continue
      continue;
    }
    const actualArray = actual.split(' ');
    for (let j = 0; j < actualArray.length; j += 1) {
      const actualEntry = actualArray[j];
      if (e === actualEntry
        || (actualEntry.length >= 3 && e.startsWith(actualEntry))
        || e === numberMapping[actualEntry]) {
        return true;
      }
    }
  }
  return false;
};

export default ({
  firestore,
  auth,
}) => {
  const [error, setError] = useState(null);
  const match = useRouteMatch();

  // roomDoc
  const [roomDoc, setRoomDoc] = useState(null);
  useEffect(() => {
    if (!firestore
      || !match
      || !match.params.name
      || !match.params.roomId) {
      return () => {};
    }
    const unsubscribe = firestore.collection('rooms').doc(match.params.roomId)
      .onSnapshot((ss) => {
        if (!ss.exists) {
          setError(new Error(ROOM_DOES_NOT_EXIST));
          return;
        }
        setRoomDoc(ss);
      });
    return () => { unsubscribe(); };
  }, [firestore, match]);

  // playerDoc
  const [playerDoc, setPlayerDoc] = useState(null);
  useEffect(() => {
    if (!firestore
      || !match
      || !match.params.name
      || !match.params.roomId
    ) {
      return () => {};
    }
    const unsubscribe = firestore.collection('rooms').doc(match.params.roomId)
      .collection('players').doc(match.params.name)
      .onSnapshot((ss) => {
        setPlayerDoc(ss);
      });
    return () => { unsubscribe(); };
  }, [firestore, match]);

  // playerDocs
  const [playerDocs, setPlayerDocs] = useState(null);
  useEffect(() => {
    if (!firestore
      || !match
      || !match.params.roomId
    ) {
      return () => {};
    }
    const unsubscribe = firestore.collection('rooms').doc(match.params.roomId)
      .collection('players')
      .onSnapshot((ss) => {
        setPlayerDocs(ss);
      });
    return () => { unsubscribe(); };
  }, [firestore, match]);

  // join room
  useEffect(() => {
    if (!roomDoc
      || !playerDoc
      || playerDoc.exists // already in, no need to join again
    ) {
      return;
    }
    try {
      joinRoom(roomDoc, playerDoc, auth.user.uid, GAME_SELECTION.toLowerCase());
    } catch (e) {
      setError(e);
    }
  }, [auth, roomDoc, playerDoc]);

  // update categories
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const fetchCategories = async () => {
      // eslint-disable-next-line no-undef
      const response = await fetch('https://trivia-tp3f5lklnq-uc.a.run.app/triviaCategories.json');
      const json = await response.json();
      setCategories(json.sort());
    };
    fetchCategories();
  }, [categories]);

  if (error) {
    return <ErrorPage error={error} />;
  }
  if (!roomDoc || !playerDoc || !playerDoc.exists) {
    return <div />;
  }

  switch (roomDoc.data().roomState) {
    case 'WAITING':
      return (
        <RoomWaiting
          roomDoc={roomDoc}
          playerDoc={playerDoc}
          playerDocs={playerDocs}
          gameSelection={GAME_SELECTION}
          categories={categories}
        />
      );
    case 'FINISHED':
      return (
        <RoomFinished
          roomDoc={roomDoc}
          playerDoc={playerDoc}
          playerDocs={playerDocs}
          gameSelection={GAME_SELECTION}
        />
      );
    case 'PLAYING':
      return (
        <RoomPlaying
          firestore={firestore}
          roomDoc={roomDoc}
          playerDoc={playerDoc}
          playerDocs={playerDocs}
          gameSelection={GAME_SELECTION}
          setError={setError}
        />
      );
    default:
      throw new Error(`invalid roomState: ${roomDoc.data().roomState}`);
  }
};
