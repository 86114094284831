import React, { useEffect, useState } from 'react';
import { answerValidatorString, submitAnswer } from '../common/player';
import { getQuestion } from './Common';
import GameSummary from './GameSummary';
import Button from '../utils/Button';
import Table from '../utils/Table';

export default ({
  firestore,
  roomDoc,
  playerDoc,
  playerDocs,
  gameSelection,
  setError,
}) => {
  const [inputAnswer, setInputAnswer] = useState('NOT');
  const submitButtonFieldRef = React.createRef();

  // reset inputAnswer on questionState change
  const [prevQuestionState, setPrevQuestionState] = useState('');
  useEffect(() => {
    const newQuestionState = roomDoc.data().questionState;
    if (newQuestionState === prevQuestionState) {
      return;
    }
    setInputAnswer('');
    setPrevQuestionState(newQuestionState);
  }, [roomDoc, prevQuestionState]);

  const checkAnswers = () => {
    const url = new URL(`https://us-central1-trivia-03212020.cloudfunctions.net/${gameSelection.toLowerCase()}-playingAnswer`);
    url.searchParams.append('roomId', roomDoc.id);
    // eslint-disable-next-line no-undef
    fetch(url);
  };

  const nextQuestion = () => {
    const url = new URL(`https://us-central1-trivia-03212020.cloudfunctions.net/${gameSelection.toLowerCase()}-playingQuestion`);
    url.searchParams.append('roomId', roomDoc.id);
    // eslint-disable-next-line no-undef
    fetch(url);
  };

  return (
    <div className="PlayerPage">
      <br />
      <div className="columns">
        <div className="column col-6 col-md-8 col-sm-10 col-xs-12 col-mx-auto text-center">
          <h3>
            {`Player: ${playerDoc.id}`}
          </h3>
          <p>
            {`Question ${roomDoc.data().round}/${roomDoc.data().numRounds}`}
          </p>
          <h4>{getQuestion(roomDoc)}</h4>
          {roomDoc.data().questionState === 'WAITING' && (
            <div>
              {playerDoc.data().state === 'INPUTTING' && (
                <div>
                  {playerDoc.data().playerUid === roomDoc.data().ownerUid && (
                    <div>
                      <Button
                        onClick={nextQuestion}
                        className="btn btn-block"
                        text="Skip Question"
                      />
                      <br />
                    </div>
                  )}
                  <div className="columns">
                    <div className="column col-mx-auto">
                      <div className="form-group">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th>Select</th>
                              <th>Player</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              onClick={() => {
                                setInputAnswer('HAVE');
                              }}
                            >
                              <td>
                                <input
                                  className="form-radio"
                                  type="radio"
                                  value="HAVE"
                                  checked={inputAnswer === 'HAVE'}
                                  onChange={(event) => {
                                    setInputAnswer(event.target.value);
                                  }}
                                />
                                <i className="form-icon" />
                              </td>
                              <td>
                                I Have
                              </td>
                            </tr>
                            <tr
                              onClick={() => {
                                setInputAnswer('NOT');
                              }}
                            >
                              <td>
                                <input
                                  className="form-radio"
                                  // eslint-disable-next-line jsx-a11y/no-autofocus
                                  autoFocus
                                  type="radio"
                                  value="NOT"
                                  checked={inputAnswer === 'NOT'}
                                  onChange={(event) => {
                                    setInputAnswer(event.target.value);
                                  }}
                                />
                                <i className="form-icon" />
                              </td>
                              <td>
                                I Have Never
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <br />
                      <Button
                        onClick={() => {
                          try {
                            submitAnswer(
                              firestore,
                              roomDoc.id,
                              playerDoc.id,
                              inputAnswer,
                              answerValidatorString,
                            );
                          } catch (e) {
                            setError(e);
                          }
                        }}
                        className="btn btn-primary btn-block"
                        ref={submitButtonFieldRef}
                        text="Submit"
                      />
                      <br />
                    </div>
                  </div>
                </div>
              )}
              {playerDoc.data().state === 'SUBMITTED' && (
                <div>
                  <p>
                    Your Answer:&nbsp;
                    <strong>{playerDoc.data().answer === 'HAVE' ? 'I have' : 'I have not'}</strong>
                  </p>
                  <p>Waiting for other participants to submit their answers.</p>
                  {playerDoc.data().playerUid === roomDoc.data().ownerUid && (
                    <div>
                      <Button
                        onClick={checkAnswers}
                        className="btn btn-primary btn-block"
                        text="Check Answers"
                        disableDuration={5000}
                      />
                      <br />
                    </div>
                  )}
                  <Table
                    colNames={['Participant', 'Score', 'Submitted']}
                    rows={playerDocs && playerDocs.docs.map((doc) => ({
                      key: doc.id,
                      columns: [
                        doc.id,
                        doc.data().score,
                        doc.data().state === 'SUBMITTED' ? '✅' : '❌',
                      ],
                    }))}
                  />
                </div>
              )}
            </div>
          )}
          {roomDoc.data().questionState === 'INTERMISSION' && (
            <div>
              <p>
                Your Answer:&nbsp;
                <strong>{playerDoc.data().answer === 'HAVE' ? 'I have' : 'I have not'}</strong>
              </p>
              {playerDoc.data().playerUid === roomDoc.data().ownerUid && (
                <div>
                  <Button
                    onClick={nextQuestion}
                    className="btn btn-primary btn-block"
                    text="Next Question"
                    disableDuration={5000}
                  />
                  <br />
                </div>
              )}
              <GameSummary
                playerDoc={playerDoc}
                playerDocs={playerDocs}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
