import {
  ROOM_DOES_NOT_EXIST,
} from '../common/errors';

export const getVotesPerPlayer = (playerSS) => {
  if (playerSS) {
    return Object
      .entries(playerSS
        .docs
        .filter((doc) => doc.data().answer && doc.data().answer.length > 0)
        .reduce((acc, doc) => {
          const { answer } = doc.data();
          if (!acc[answer]) {
            acc[answer] = [];
          }
          acc[answer].push(doc.id);
          return acc;
        }, {}))
      .sort((a, b) => a[1].length - b[1].length);
  }
  return {};
};

export const getConsensus = (playerSS) => {
  if (playerSS) {
    const votes = getVotesPerPlayer(playerSS);
    if (votes.length > 0) {
      if (votes.length > 1
      && votes[votes.length - 1][1].length === votes[votes.length - 2][1].length) {
        return 'No Consensus';
      }
      return votes[votes.length - 1][0];
    }
  }
  return '';
};

export const getQuestion = (roomDoc) => {
  if (!roomDoc || !roomDoc.exists) {
    throw new Error(ROOM_DOES_NOT_EXIST);
  }
  const {
    round,
    questions,
  } = roomDoc.data();
  if (!questions || questions.length < round) {
    return '';
  }
  return questions[round - 1].question;
};

export default {};
