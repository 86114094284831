import React from 'react';
import Button from '../utils/Button';
import GameSummary from './GameSummary';

export default ({
  roomDoc,
  playerDoc,
  gameSelection,
}) => {
  const newGame = () => {
    const url = new URL(`https://us-central1-trivia-03212020.cloudfunctions.net/${gameSelection.toLowerCase()}-newGame`);
    url.searchParams.append('roomId', roomDoc.id);
    // eslint-disable-next-line no-undef
    fetch(url);
  };
  return (
    <div className="PlayerPage">
      <br />
      <div className="columns">
        <div className="column col-6 col-md-8 col-sm-10 col-xs-12 col-mx-auto text-center">
          <h1>{gameSelection}</h1>
          <h2>
            {`Room: ${roomDoc.id}`}
          </h2>
          <h3>
            {`Player: ${playerDoc.id}`}
          </h3>
          <h3>Game Over</h3>
          <br />
          {playerDoc.data().playerUid === roomDoc.data().ownerUid && (
            <Button
              onClick={newGame}
              className="btn btn-primary btn-block"
              text="New Game"
              disableDuration={5000}
            />
          )}
          <br />
          <GameSummary
            roomDoc={roomDoc}
            playerDoc={playerDoc}
          />
        </div>
      </div>
    </div>
  );
};
