import {
  ROOM_DOES_NOT_EXIST,
} from '../common/errors';

const question = (roomDoc) => {
  if (!roomDoc || !roomDoc.exists) {
    throw new Error(ROOM_DOES_NOT_EXIST);
  }
  const {
    round,
    questions,
  } = roomDoc.data();
  if (!questions) {
    return {};
  }
  return questions[round - 1] || {};
};

export const getQuestion = (roomDoc) => question(roomDoc).question;

export default {};
