import React, { useState, useEffect } from 'react';

import Story from './Story';
import Button from '../utils/Button';

/**
 * props = {
 *  roomId,
 * }
 */
export default ({
  firestore,
  analytics,
  propsStoryIndex,
  roomId,
}) => {
  const [storyIndex, setStoryIndex] = useState(propsStoryIndex || 0);
  const [numStories, setNumStories] = useState(0);

  // get num stories
  useEffect(() => {
    const fetchData = async () => {
      const stories = await firestore.collection('rooms').doc(roomId)
        .collection('stories')
        .get();
      setNumStories(stories.docs.length);
    };
    fetchData();
  }, [firestore, roomId]);

  const previousStory = () => {
    if (numStories > 0) {
      if (storyIndex - 1 < 0) {
        setStoryIndex(numStories - 1);
      } else {
        setStoryIndex(storyIndex - 1);
      }
    }
  };

  const nextStory = () => {
    if (numStories > 0) {
      if (storyIndex + 1 >= numStories) {
        setStoryIndex(0);
      } else {
        setStoryIndex(storyIndex + 1);
      }
    }
  };

  return (
    <div className="Storybook">
      <div className="btn-group btn-group-block">
        <Button
          onClick={previousStory}
          className="btn"
          text={<i className="far fa-arrow-alt-circle-left" style={{ marginLeft: '40px', marginRight: '40px' }} />}
        />
        <Button
          onClick={nextStory}
          className="btn"
          text={<i className="far fa-arrow-alt-circle-right" style={{ marginLeft: '40px', marginRight: '40px' }} />}
        />
      </div>
      {numStories > 0 && (
        <div>
          <Story
            firestore={firestore}
            analytics={analytics}
            roomId={roomId}
            storyIndex={storyIndex}
          />
        </div>
      )}
      <br />
      <br />
      <br />
    </div>
  );
};
