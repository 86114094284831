import {
  ROOM_DOES_NOT_EXIST,
  ROOM_WRONG_GAME_TYPE,
  ROOM_ALREADY_PLAYING,
  // PLAYER_UID_DIFFERS,
} from './errors';

export const joinRoom = (roomDoc, playerDoc, uid, gameSelection) => {
  // check room exists
  if (!roomDoc.exists) {
    throw new Error(ROOM_DOES_NOT_EXIST);
  }
  // check room is of right game selection
  if (roomDoc.data().gameSelection !== gameSelection) {
    throw new Error(ROOM_WRONG_GAME_TYPE);
  }
  // check can join room (game hasn't started || i'm already in room)
  let playerStateOnJoin = 'WAITING';
  if (!['NEW_GAME', 'WAITING'].includes(roomDoc.data().roomState)) {
    if (!playerDoc.exists) {
      throw new Error(ROOM_ALREADY_PLAYING);
    }
    playerStateOnJoin = 'INPUTTING';
  }
  // allow players to rejoin as different uid (e.g. if they're connecting from FB Messenger)
  // if (playerDoc.exists // only check uid if player exists
  //   && playerDoc.data().playerUid !== uid) {
  //   throw new Error(PLAYER_UID_DIFFERS);
  // }
  const join = async () => {
    await roomDoc.ref
      .collection('players')
      .doc(playerDoc.id)
      .set({
        state: playerStateOnJoin,
        playerUid: uid,
        name: playerDoc.id,
      }, { merge: true });
    console.log(`Player ${playerDoc.id} joins ${roomDoc.id}`);
  };
  join();
};

export default joinRoom;
