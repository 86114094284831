/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from './utils/Button';
import Header from './utils/Header';

import {
  ERROR_TYPE_TO_MESSAGE,
} from './utils/ErrorPage';

export default () => {
  const history = useHistory();

  const [inputName, setInputName] = useState('');
  const [inputNameError, setInputNameError] = useState(null);

  const submitButtonFieldRef = React.createRef();

  const [gameSelection, setGameSelection] = useState('trivia');

  const playGame = async () => {
    // create game
    history.push(`/${gameSelection}/player/${inputName}`);
  };

  return (
    <div className="Home">
      <Header />
      <div className="columns">
        <div className="column col-auto col-mx-auto">
          <h2>
            Create a Game
          </h2>
        </div>
      </div>
      <div className="columns">
        <div className="column col-6 col-mx-auto">
          <div className="form-group">
            <div className="columns">
              <div className="column col-auto col-mr-auto">
                <label
                  className="form-label"
                  htmlFor="inputName"
                >
                  Name
                </label>
              </div>
            </div>
            <div className="columns">
              <div className="column col-mx-auto">
                <input
                  className={inputNameError ? 'form-input is-error' : 'form-input'}
                  autoFocus
                  type="text"
                  id="inputName"
                  placeholder="Name (Optional)"
                  maxLength="26"
                  value={inputName}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter' && submitButtonFieldRef) {
                      submitButtonFieldRef.current.click();
                    }
                  }}
                  onChange={(event) => {
                    setInputName(event.target.value);
                    setInputNameError(null);
                  }}
                />
                {inputNameError && (
                  <p className="form-input-hint">
                    {ERROR_TYPE_TO_MESSAGE[inputNameError].error
                    + ERROR_TYPE_TO_MESSAGE[inputNameError].message}
                  </p>
                )}
              </div>
            </div>
            <div className="columns">
              <div className="column col-auto col-mr-auto">
                <label className="form-label" htmlFor="gameSelection">Game Type</label>
              </div>
            </div>
            <div className="columns">
              <div className="column col-mx-auto">
                <select
                  className="form-select"
                  style={{ color: 'black' }}
                  value={gameSelection}
                  onChange={(event) => {
                    setGameSelection(event.target.value);
                  }}
                >
                  <option value="trivia">Trivia</option>
                  <option value="telestrations">Telestrations</option>
                  <option value="karaoke">Robot Karaoke</option>
                  <option value="consensus">Consensus</option>
                  <option value="insync">In Sync</option>
                  <option value="neverhaveiever">Never Have I Ever</option>
                </select>
              </div>
            </div>
            <br />
            <div className="columns">
              <div className="column col-mx-auto">
                <Button
                  onClick={playGame}
                  className="btn btn-primary btn-block"
                  text="Create"
                  disableDuration={5000}
                />
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};
