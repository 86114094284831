import React from 'react';
import { ERROR_TYPE_TO_MESSAGE } from './ErrorPage';

export default ({
  numRounds,
  setNumRounds,
  numRoundsError,
  setNumRoundsError,
}) => (
  <div>
    <div className="input-group">
      <span className="input-group-addon">Rounds (max 50)</span>
      <input
        className={numRoundsError ? 'form-input is-error' : 'form-input'}
        type="number"
        id="numRounds"
        min="1"
        max="50"
        value={numRounds}
        onChange={(event) => {
          setNumRounds(event.target.value);
          setNumRoundsError(null);
        }}
      />
    </div>
    {numRoundsError && (
      <p className="form-input-hint">
        {`${ERROR_TYPE_TO_MESSAGE[numRoundsError].error}: ${ERROR_TYPE_TO_MESSAGE[numRoundsError].message}`}
      </p>
    )}
  </div>
);
