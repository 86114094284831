import React from 'react';

export default ({
  inputCategory,
  setInputCategory,
  categories,
}) => (
  <div className="input-group">
    <span className="input-group-addon">Category</span>
    <select
      className="form-select"
      style={{ color: 'black' }}
      value={inputCategory}
      onChange={(event) => {
        setInputCategory(event.target.value);
      }}
    >
      {categories.map((category) => (
        <option value={category} key={category}>{category}</option>
      ))}
    </select>
  </div>
);
