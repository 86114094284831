export const PAGE_DOES_NOT_EXIST = 'PAGE_DOES_NOT_EXIST';
export const ROOM_DOES_NOT_EXIST = 'ROOM_DOES_NOT_EXIST';
export const ROOM_WRONG_GAME_TYPE = 'ROOM_WRONG_GAME_TYPE';
export const ROOM_ALREADY_PLAYING = 'ROOM_ALREADY_PLAYING';
export const ROOM_ID_INVALID = 'ROOM_ID_INVALID';
export const BROWSER_NOT_SUPPORTED = 'BROWSER_NOT_SUPPORTED';
export const ROOM_NOT_OWNED_BY_USER = 'ROOM_NOT_OWNED_BY_USER';
export const PLAYER_UID_DIFFERS = 'PLAYER_UID_DIFFERS';
export const MUST_SPECIFY_ROOMID = 'MUST_SPECIFY_ROOMID';
export const INVALID_NUM_ROUNDS = 'INVALID_NUM_ROUNDS';

export const SUBMIT_FAILURE_ROOM_NOT_PLAYING = 'SUBMIT_FAILURE_ROOM_NOT_PLAYING';
export const SUBMIT_FAILURE_ROOM_QUESTION_NOT_WAITING = 'SUBMIT_FAILURE_ROOM_QUESTION_NOT_WAITING';
export const SUBMIT_FAILURE_PLAYER_NOT_IN_ROOM = 'SUBMIT_FAILURE_PLAYER_NOT_IN_ROOM';
export const SUBMIT_FAILURE_ALREADY_SUBMITTED = 'SUBMIT_FAILURE_ALREADY_SUBMITTED';
