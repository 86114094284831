/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import {
  submitAnswer,
  answerValidatorString,
} from '../common/player';
import Button from '../utils/Button';
import Table from '../utils/Table';
import { getConsensus, getQuestion, getVotesPerPlayer } from './Common';

export default ({
  firestore,
  roomDoc,
  playerDoc,
  playerDocs,
  gameSelection,
  setError,
}) => {
  const [inputAnswer, setInputAnswer] = useState(playerDoc.id);
  const submitButtonFieldRef = React.createRef();

  // reset inputAnswer on questionState change
  const [prevQuestionState, setPrevQuestionState] = useState('');
  useEffect(() => {
    const newQuestionState = roomDoc.data().questionState;
    if (newQuestionState === prevQuestionState) {
      return;
    }
    setInputAnswer('');
    setPrevQuestionState(newQuestionState);
  }, [roomDoc, prevQuestionState]);

  const checkAnswers = () => {
    const url = new URL(`https://us-central1-trivia-03212020.cloudfunctions.net/${gameSelection.toLowerCase()}-playingAnswer`);
    url.searchParams.append('roomId', roomDoc.id);
    // eslint-disable-next-line no-undef
    fetch(url);
  };

  const nextQuestion = () => {
    const url = new URL(`https://us-central1-trivia-03212020.cloudfunctions.net/${gameSelection.toLowerCase()}-playingQuestion`);
    url.searchParams.append('roomId', roomDoc.id);
    // eslint-disable-next-line no-undef
    fetch(url);
  };

  const renderPlayerName = (name, isMe = false) => (isMe ? <strong>{`${name} (You)`}</strong> : name);
  const votesPerPlayer = getVotesPerPlayer(playerDocs);

  return (
    <div className="PlayerPage">
      <br />
      <div className="columns">
        <div className="column col-6 col-md-8 col-sm-10 col-xs-12 col-mx-auto text-center">
          <h3>
            {`Player: ${playerDoc.id}`}
          </h3>
          <p>
            {`Question ${roomDoc.data().round}/${roomDoc.data().numRounds}`}
          </p>
          <h4>{getQuestion(roomDoc)}</h4>
          {roomDoc.data().questionState === 'WAITING' && (
            <div>
              {playerDoc.data().state === 'INPUTTING' && (
                <div>
                  {playerDoc.data().playerUid === roomDoc.data().ownerUid && (
                    <div>
                      <Button
                        onClick={nextQuestion}
                        className="btn btn-block"
                        text="Skip Question"
                      />
                    </div>
                  )}
                  <div className="form-group">
                    <label className="form-label">Choose a Player</label>
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>Select</th>
                          <th>Player</th>
                        </tr>
                      </thead>
                      <tbody>
                        {playerDocs && playerDocs.docs
                          .sort((a, b) => {
                            const nameA = a.data().name.toUpperCase();
                            const nameB = b.data().name.toUpperCase();
                            if (nameA < nameB) {
                              return -1;
                            }
                            if (nameA > nameB) {
                              return 1;
                            }
                            return 0;
                          })
                          .map((player) => {
                            const isMe = playerDoc.id
                              ? playerDoc.id === player.id
                              : false;
                            return (
                              <tr
                                key={player.data().name}
                                onClick={() => {
                                  if (player && player.exists) {
                                    setInputAnswer(player.data().name);
                                  }
                                }}
                              >
                                <td>
                                  <input
                                    type="radio"
                                    name="player"
                                    value={player.data().name}
                                    checked={player.data().name === inputAnswer}
                                    onChange={(event) => {
                                      setInputAnswer(event.target.value);
                                    }}
                                  />
                                  <i className="form-icon" />
                                </td>
                                <td>
                                  {renderPlayerName(player.data().name, isMe)}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <Button
                    onClick={() => {
                      try {
                        submitAnswer(
                          firestore,
                          roomDoc.id,
                          playerDoc.id,
                          inputAnswer,
                          answerValidatorString,
                        );
                      } catch (e) {
                        setError(e);
                      }
                    }}
                    className="btn btn-primary btn-block"
                    ref={submitButtonFieldRef}
                    text="Submit"
                  />
                </div>
              )}
              {playerDoc.data().state === 'SUBMITTED' && (
                <div>
                  <br />
                  <p>
                    Your Answer:&nbsp;
                    <strong>{playerDoc.data().answer || '...'}</strong>
                  </p>
                  <p>Waiting for other participants to submit their answers.</p>
                  {playerDoc.data().playerUid === roomDoc.data().ownerUid && (
                    <div>
                      <Button
                        onClick={checkAnswers}
                        className="btn btn-primary btn-block"
                        text="Check Answers"
                        disableDuration={5000}
                      />
                      <br />
                    </div>
                  )}
                  <Table
                    colNames={['Participants', 'Ready']}
                    rows={playerDocs.docs.map((doc) => ({
                      key: doc.id,
                      columns: [
                        doc.id,
                        doc.data().state === 'SUBMITTED' ? '✅' : '❌',
                      ],
                    }))}
                  />
                </div>
              )}
            </div>
          )}
          {roomDoc.data().questionState === 'INTERMISSION' && (
            <div>
              <br />
              <p>
                Your Answer:&nbsp;
                <strong>{playerDoc.data().answer || '...'}</strong>
              </p>
              <p>
                Consensus:&nbsp;
                <strong>{getConsensus(playerDocs)}</strong>
              </p>
              {playerDoc.data().playerUid === roomDoc.data().ownerUid && (
                <div>
                  <Button
                    onClick={nextQuestion}
                    className="btn btn-primary btn-block"
                    text="Next Question"
                    disableDuration={5000}
                  />
                  <br />
                </div>
              )}
              <Table
                colNames={['Participants', 'Voters']}
                rows={Object
                  .entries(votesPerPlayer)
                  .sort(([, [, avotes]], [, [, bvotes]]) => bvotes.length - avotes.length)
                  .map(([, [name, voters]]) => ({
                    key: name,
                    columns: [
                      `${name} (${voters.length})`,
                      voters.join(', '),
                    ],
                  }))}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
