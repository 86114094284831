import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';

import ErrorPage from '../utils/ErrorPage';
import {
  ROOM_DOES_NOT_EXIST,
} from '../common/errors';
import { joinRoom } from '../common/room';

import RoomWaiting from '../shared/RoomWaiting';
import RoomFinished from './RoomFinished';
import RoomPlaying from './RoomPlaying';

const GAME_SELECTION = 'Consensus';

export default ({
  firestore,
  auth,
}) => {
  const [error, setError] = useState(null);
  const match = useRouteMatch();

  // roomDoc
  const [roomDoc, setRoomDoc] = useState(null);
  useEffect(() => {
    if (!firestore
      || !match
      || !match.params.name
      || !match.params.roomId) {
      return () => {};
    }
    const unsubscribe = firestore.collection('rooms').doc(match.params.roomId)
      .onSnapshot((ss) => {
        if (!ss.exists) {
          setError(new Error(ROOM_DOES_NOT_EXIST));
          return;
        }
        setRoomDoc(ss);
      });
    return () => { unsubscribe(); };
  }, [firestore, match]);

  // playerDoc
  const [playerDoc, setPlayerDoc] = useState(null);
  useEffect(() => {
    if (!firestore
      || !match
      || !match.params.name
      || !match.params.roomId
    ) {
      return () => {};
    }
    const unsubscribe = firestore.collection('rooms').doc(match.params.roomId)
      .collection('players').doc(match.params.name)
      .onSnapshot((ss) => {
        setPlayerDoc(ss);
      });
    return () => { unsubscribe(); };
  }, [firestore, match]);

  // playerDocs
  const [playerDocs, setPlayerDocs] = useState(null);
  useEffect(() => {
    if (!firestore
      || !match
      || !match.params.roomId
    ) {
      return () => {};
    }
    const unsubscribe = firestore.collection('rooms').doc(match.params.roomId)
      .collection('players')
      .onSnapshot((ss) => {
        setPlayerDocs(ss);
      });
    return () => { unsubscribe(); };
  }, [firestore, match]);

  // join room
  useEffect(() => {
    if (!roomDoc
      || !playerDoc
      || playerDoc.exists // already in, no need to join again
    ) {
      return;
    }
    try {
      joinRoom(roomDoc, playerDoc, auth.user.uid, GAME_SELECTION.toLowerCase());
    } catch (e) {
      setError(e);
    }
  }, [auth, roomDoc, playerDoc]);

  // update categories
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const fetchCategories = async () => {
      // eslint-disable-next-line no-undef
      const response = await fetch('https://trivia-tp3f5lklnq-uc.a.run.app/consensusCategories.json');
      const json = await response.json();
      setCategories(json.sort());
    };
    fetchCategories();
  }, [categories]);

  if (error) {
    return <ErrorPage error={error} />;
  }
  if (!roomDoc || !playerDoc || !playerDoc.exists) {
    return <div />;
  }
  switch (roomDoc.data().roomState) {
    case 'WAITING':
      return (
        <RoomWaiting
          roomDoc={roomDoc}
          playerDoc={playerDoc}
          playerDocs={playerDocs}
          gameSelection={GAME_SELECTION}
          categories={categories}
        />
      );
    case 'FINISHED':
      return (
        <RoomFinished
          roomDoc={roomDoc}
          playerDoc={playerDoc}
          gameSelection={GAME_SELECTION}
        />
      );
    case 'PLAYING':
      return (
        <RoomPlaying
          firestore={firestore}
          roomDoc={roomDoc}
          playerDoc={playerDoc}
          playerDocs={playerDocs}
          gameSelection={GAME_SELECTION}
          setError={setError}
        />
      );
    default:
      throw new Error(`invalid roomState: ${roomDoc.data().roomState}`);
  }
};
