import {
  ROOM_DOES_NOT_EXIST,
  SUBMIT_FAILURE_ALREADY_SUBMITTED,
  SUBMIT_FAILURE_PLAYER_NOT_IN_ROOM,
  SUBMIT_FAILURE_ROOM_NOT_PLAYING,
  SUBMIT_FAILURE_ROOM_QUESTION_NOT_WAITING,
} from './errors';

export const answerValidatorString = (answer) => answer.length > 0;
export const answerValidatorTitleAndArtist = (answer) => !(answer.title.length === 0
  && answer.artist.length === 0);

export const submitAnswer = async (firestore, roomId, playerName,
  inputAnswer, answerValidatorFn) => {
  if (!answerValidatorFn(inputAnswer)) {
    // invalid answer
    return;
  }
  const roomRef = firestore.collection('rooms').doc(roomId);
  const playerRef = roomRef.collection('players').doc(playerName);
  await firestore.runTransaction(async (trans) => {
    const room = await trans.get(roomRef);
    if (!room.exists) {
      throw new Error(ROOM_DOES_NOT_EXIST);
    }
    if (room.data().roomState !== 'PLAYING') {
      throw new Error(SUBMIT_FAILURE_ROOM_NOT_PLAYING);
    }
    if (room.data().questionState !== 'WAITING') {
      throw new Error(SUBMIT_FAILURE_ROOM_QUESTION_NOT_WAITING);
    }
    const player = await trans.get(playerRef);
    if (!player.exists) {
      throw new Error(SUBMIT_FAILURE_PLAYER_NOT_IN_ROOM);
    }
    if (player.data().state === 'SUBMITTED') {
      throw new Error(SUBMIT_FAILURE_ALREADY_SUBMITTED);
    }
    await trans.update(playerRef, {
      answer: inputAnswer,
      state: 'SUBMITTED',
    });
  });
};

export default submitAnswer;
