/* eslint-disable no-bitwise */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-undef */
import React from 'react';

import * as PIXI from 'pixi.js';

import Button from '../utils/Button';

const download = (pixiApp) => {
  const anchor = document.createElement('a');
  anchor.href = pixiApp.renderer.view.toDataURL('image/png').replace('image/png', 'image/octet-stream');
  anchor.download = 'telestrations.png';
  document.body.appendChild(anchor);
  anchor.click();
};

export default class SketchpadBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasLoaded: false,
    };

    this.midPoint = null;
    this.lastPoint = null;
    this.lastMidPoint = null;
    this.pixiRef = React.createRef();
  }

  componentDidMount() {
    // eslint-disable-next-line no-undef
    const resolution = window.devicePixelRatio || 1;
    this.pixiApp = new PIXI.Application({
      resolution,
      transparent: false,
      backgroundColor: 0xffffff,
      resizeTo: this.container,
      preserveDrawingBuffer: true, // for toDataUrl();
      sharedLoader: true,
      sharedTicker: true,
    });
    this.pixiApp.resize();
    this.pixiApp.view.style.width = '100%';
    this.pixiApp.view.style.height = '100%';
    this.pixiApp.view.style.border = 'black 3px dotted';

    this.brush = new PIXI.Graphics();

    this.pixiApp.loader.load(() => {
      this.renderTexture = PIXI.RenderTexture.create({
        width: this.pixiApp.screen.width,
        height: this.pixiApp.screen.height,
      });

      this.renderTextureSprite = new PIXI.Sprite(this.renderTexture);
      this.pixiApp.stage.addChild(this.renderTextureSprite);

      this.pixiApp.stage.interactive = this.props.isInteractive;
      this.pixiApp.stage.on('pointerdown', this.pointerDown.bind(this));
    });

    this.pixiRef.current.appendChild(this.pixiApp.view);
    this.redraw();
  }

  componentDidUpdate(previousProps) {
    if (previousProps.isInteractive !== this.props.isInteractive) {
      this.pixiApp.stage.interactive = this.props.isInteractive;
    }
    let redraw = false;
    redraw |= (this.props.clearTime !== previousProps.clearTime);
    redraw |= (this.props.initialPoints && !previousProps.initialPoints);
    redraw |= (this.props.initialPoints
      && previousProps.initialPoints
      && this.props.initialPoints.length !== previousProps.initialPoints.length);
    if (redraw) {
      this.redraw();
    }
  }

  componentWillUnmount() {
    if (this.brush) {
      this.brush.destroy();
    }
    if (this.renderTextureSprite) {
      this.renderTextureSprite.destroy();
    }
    if (this.historyRenderTextureSprite) {
      this.historyRenderTextureSprite.destroy();
    }
    if (this.renderTexture) {
      this.renderTexture.destroy();
    }
    if (this.historyRenderTexture) {
      this.historyRenderTexture.destroy();
    }
    if (this.pixiApp) {
      this.pixiApp.destroy();
    }
  }

  drawPoint(point, render = true) {
    if (point.x === -1 && point.y === -1) {
      return;
    }
    if (this.lastPoint !== null && this.lastMidPoint !== null) {
      this.midPoint.set(this.lastPoint.x + point.x >> 1, this.lastPoint.y + point.y >> 1);
      this.brush.moveTo(this.midPoint.x, this.midPoint.y);
      this.brush.quadraticCurveTo(
        this.lastPoint.x,
        this.lastPoint.y,
        this.lastMidPoint.x,
        this.lastMidPoint.y,
      );
      this.lastMidPoint.set(this.midPoint.x, this.midPoint.y);
      this.lastPoint.set(point.x, point.y);
    }
    if (render) {
      this.pixiApp.renderer.render(
        this.brush,
        this.renderTexture,
        false,
        undefined,
        false,
      );
    }
  }

  stopDrawing() {
    this.brush.endFill();
  }

  startDrawing(point) {
    this.brush.beginFill(0x0);
    this.brush.lineStyle(3, 0x0, 2);
    if (point.x !== -1 && point.y !== -1) {
      this.brush.moveTo(point.x, point.y);
      this.brush.lineTo(point.x, point.y);
      this.midPoint = new PIXI.Point();
      this.lastPoint = new PIXI.Point(point.x, point.y);
      this.lastMidPoint = new PIXI.Point(point.x, point.y);
    }
  }

  pointerMove(event) {
    const point = {
      x: event.data.global.x,
      y: event.data.global.y,
    };
    if (this.props.addPoint) {
      this.props.addPoint(point);
    }
    this.drawPoint(point);
  }

  pointerUp() {
    this.stopDrawing();
    this.brush.clear();
    if (this.props.addPoint) {
      // -1,-1 is a watermark to indicate mouseUp events.
      // this'll prevent pixi from connecting these dots.
      this.props.addPoint({ x: -1, y: -1 });
    }
    this.pixiApp.stage.off('pointermove');
    this.pixiApp.stage.off('pointerup');
  }

  pointerDown(event) {
    this.startDrawing({ x: event.data.global.x, y: event.data.global.y });
    this.pixiApp.stage.on('pointermove', this.pointerMove.bind(this));
    this.pixiApp.stage.on('pointerup', this.pointerUp.bind(this));
  }

  redraw() {
    this.setState({
      hasLoaded: false,
    });
    const startTime = performance.now();
    if (this.brush) {
      this.brush.destroy();
    }
    this.brush = new PIXI.Graphics();
    if (this.props.initialPoints && this.props.initialPoints.length > 0) {
      let stopped = true;
      for (let i = 0; i < this.props.initialPoints.length; i += 1) {
        const point = this.props.initialPoints[i];
        if (point.x === -1 && point.y === -1) {
          this.stopDrawing();
          stopped = true;
          // eslint-disable-next-line no-continue
          continue;
        } else if (stopped) {
          stopped = false;
          this.startDrawing(point);
        }
        this.drawPoint(point, false);
      }
      this.stopDrawing();
    }
    // render no matter what (at minimum, clear the old shit)
    this.pixiApp.renderer.render(
      this.brush,
      this.renderTexture,
      true, // clear the old shit
      undefined,
      false,
    );
    this.pixiApp.stage.interactive = this.props.isInteractive;
    this.setState({
      hasLoaded: true,
    });
    const elapsedMs = performance.now() - startTime;
    const numPoints = (this.props.initialPoints || []).length;
    this.props.analytics.logEvent('sketchpad_redraw', {
      elapsedMs,
      num_points: numPoints,
    });
    console.log(`sketchpad_redraw: ${Math.floor(elapsedMs * 1000) / 1000}ms for ${numPoints} points`);
  }

  render() {
    return (
      <div className="Sketchpad">
        <div className="columns">
          <div className="column col-mx-auto col-12">
            <div
              style={{
                margin: 'auto',
                minHeight: '500px',
                maxHeight: '550px',
                minWidth: '280px',
                maxWidth: '400px',
                width: '95vw',
                display: 'flex',
                overflow: 'hidden',
                position: 'relative',
              }}
              className="sketchpadContainer"
              ref={(el) => { this.container = el; }}
            >
              {!this.props.isInteractive && (
                <div
                  className="layer"
                  style={{
                    width: '100%',
                    height: '100%',
                    zIndex: 100,
                    position: 'absolute',
                    backgroundColor: 'rgba(0,0,0,0)',
                  }}
                />
              )}
              {!this.state.hasLoaded && (
                <div
                  className="loading loading-lg"
                  style={{
                    width: '100%',
                    height: '100%',
                    zIndex: 90,
                    position: 'absolute',
                    backgroundColor: 'rgba(255,255,255)',
                  }}
                />
              )}
              <div ref={this.pixiRef} />
            </div>
          </div>
        </div>
        {this.props.downloadButton && (
          <div>
            <br />
            <Button
              onClick={() => {
                download(this.pixiApp);
                this.props.analytics.logEvent('sketchpad.download');
              }}
              className="btn btn-block"
              text={<i className="fas fa-file-download" />}
            />
          </div>
        )}
      </div>
    );
  }
}
