import React, { useEffect, useState } from 'react';
import { answerValidatorTitleAndArtist, submitAnswer } from '../common/player';
import { getArtist, getLyrics, getTitle } from './Common';
import Button from '../utils/Button';
import Table from '../utils/Table';
import { BROWSER_NOT_SUPPORTED } from '../common/errors';

const checkAnswer = (expectedRaw, upperRaw) => {
  const expected = (expectedRaw || '').toLowerCase().replace(/[^a-zA-Z0-9 ]/g, '');
  const actual = (upperRaw || '').toLowerCase().replace(/[^a-zA-Z0-9 ]/g, '');
  if (expected === actual) {
    return true;
  }
  if (actual.length === 0) {
    return false;
  }
  // check without spacing and punctuation
  const actualWithoutSpaces = actual.replace(/\s/g, '');
  const expectedWithoutSpaces = expected.replace(/\s/g, '');
  if (actualWithoutSpaces.length >= Math.min(expectedWithoutSpaces.length, 3)
      && expectedWithoutSpaces.includes(actualWithoutSpaces)) {
    return true;
  }
  return false;
};

export default ({
  firestore,
  roomDoc,
  playerDoc,
  playerDocs,
  gameSelection,
  setError,
}) => {
  const [inputAnswerTitle, setInputAnswerTitle] = useState('');
  const [inputAnswerArtist, setInputAnswerArtist] = useState('');
  const submitButtonFieldRef = React.createRef();

  // reset inputAnswer on questionState change
  const [prevQuestionState, setPrevQuestionState] = useState('');
  useEffect(() => {
    const newQuestionState = roomDoc.data().questionState;
    if (newQuestionState === prevQuestionState) {
      return;
    }
    setInputAnswerArtist('');
    setInputAnswerTitle('');
    setPrevQuestionState(newQuestionState);
  }, [roomDoc, prevQuestionState]);

  const checkAnswers = () => {
    const url = new URL(`https://us-central1-trivia-03212020.cloudfunctions.net/${gameSelection.toLowerCase()}-playingAnswer`);
    url.searchParams.append('roomId', roomDoc.id);
    // eslint-disable-next-line no-undef
    fetch(url);
  };

  const nextQuestion = () => {
    const url = new URL(`https://us-central1-trivia-03212020.cloudfunctions.net/${gameSelection.toLowerCase()}-playingQuestion`);
    url.searchParams.append('roomId', roomDoc.id);
    // eslint-disable-next-line no-undef
    fetch(url);
  };

  const listenOnYoutube = () => {
    const URL = `https://www.youtube.com/results?search_query=${
      encodeURIComponent((`${getTitle(roomDoc)} ${getArtist(roomDoc)}`)
        .replace(/ /g, '+'))}`;
    // eslint-disable-next-line no-undef
    window.open(URL, '_blank');
  };

  const playAudio = () => {
    // eslint-disable-next-line no-undef
    const msg = new SpeechSynthesisUtterance(getLyrics(roomDoc));
    // eslint-disable-next-line no-undef
    [msg.voice] = speechSynthesis
      .getVoices()
      .filter(({ name }) => name === 'Whisper');
    // eslint-disable-next-line no-undef
    window.speechSynthesis.speak(msg);
  };

  // eslint-disable-next-line no-undef
  if (!('speechSynthesis' in window)) {
    setError(new Error(BROWSER_NOT_SUPPORTED));
  }

  const expectedTitle = roomDoc ? getTitle(roomDoc) : '';
  const expectedArtist = roomDoc ? getArtist(roomDoc) : '';
  return (
    <div className="PlayerPage">
      <br />
      <div className="columns">
        <div className="column col-6 col-md-8 col-sm-10 col-xs-12 col-mx-auto text-center">
          <h3>
            {`Player: ${playerDoc.id}`}
          </h3>
          <p>
            {`Question ${roomDoc.data().round}/${roomDoc.data().numRounds}`}
          </p>
          <Button
            onClick={playAudio}
            className="btn btn-primary btn-action s-circle btn-lg"
            text={(<i className="fas fa-volume-up" />)}
            disableDuration={5000}
          />
          <br />
          <br />
          {roomDoc.data().questionState === 'WAITING' && (
            <div>
              {playerDoc.data().state === 'INPUTTING' && (
                <div>
                  {playerDoc.data().playerUid === roomDoc.data().ownerUid && (
                    <div>
                      <Button
                        onClick={nextQuestion}
                        className="btn btn-block"
                        text="Skip Question"
                      />
                      <br />
                    </div>
                  )}
                  <input
                    className="form-input"
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                    type="text"
                    placeholder="Title"
                    value={inputAnswerTitle}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter' && submitButtonFieldRef) {
                        submitButtonFieldRef.current.click();
                      }
                    }}
                    onChange={(event) => {
                      setInputAnswerTitle(event.target.value);
                    }}
                  />
                  <br />
                  <input
                    className="form-input"
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                    type="text"
                    placeholder="Artist"
                    value={inputAnswerArtist}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter' && submitButtonFieldRef) {
                        submitButtonFieldRef.current.click();
                      }
                    }}
                    onChange={(event) => {
                      setInputAnswerArtist(event.target.value);
                    }}
                  />
                  <br />
                  <Button
                    onClick={() => {
                      try {
                        submitAnswer(
                          firestore,
                          roomDoc.id,
                          playerDoc.id,
                          {
                            artist: inputAnswerArtist,
                            title: inputAnswerTitle,
                          },
                          answerValidatorTitleAndArtist,
                        );
                      } catch (e) {
                        setError(e);
                      }
                    }}
                    className="btn btn-primary btn-block"
                    ref={submitButtonFieldRef}
                    text="Submit"
                  />
                </div>
              )}
              {playerDoc.data().state === 'SUBMITTED' && (
                <div>
                  <br />
                  <p>
                    Your Answer:&nbsp;
                    <strong>{playerDoc.data().answer.title || '...'}</strong>
                    {' '}
                    by&nbsp;
                    <strong>{playerDoc.data().answer.artist || '...'}</strong>
                  </p>
                  <p>Waiting for other participants to submit their answers.</p>
                  {playerDoc.data().playerUid === roomDoc.data().ownerUid && (
                    <div>
                      <Button
                        onClick={checkAnswers}
                        className="btn btn-primary btn-block"
                        text="Check Answers"
                        disableDuration={5000}
                      />
                      <br />
                    </div>
                  )}
                  <Table
                    colNames={['Participant', 'Score', 'Submitted']}
                    rows={playerDocs && playerDocs.docs.map((doc) => ({
                      key: doc.id,
                      columns: [
                        doc.id,
                        doc.data().score,
                        doc.data().state === 'SUBMITTED' ? '✅' : '❌',
                      ],
                    }))}
                  />
                </div>
              )}
            </div>
          )}
          {roomDoc.data().questionState === 'INTERMISSION' && (
            <div>
              <br />
              <p>
                Your Answer:&nbsp;
                <strong>{playerDoc.data().answer.title || '...'}</strong>
                {' '}
                by&nbsp;
                <strong>{playerDoc.data().answer.artist || '...'}</strong>
              </p>
              <p>
                Accepted Answer:&nbsp;
                <strong>{getTitle(roomDoc)}</strong>
                {' '}
                by&nbsp;
                <strong>{getArtist(roomDoc)}</strong>
              </p>
              {playerDoc.data().playerUid === roomDoc.data().ownerUid && (
                <div>
                  <Button
                    onClick={nextQuestion}
                    className="btn btn-primary btn-block"
                    text="Next Question"
                    disableDuration={5000}
                  />
                  <br />
                </div>
              )}
              <Button
                onClick={listenOnYoutube}
                className="btn btn-block"
                text="Listen on Youtube"
              />
              <br />
              <Table
                colNames={['Participant', 'Score', 'Title', 'Artist']}
                rows={playerDocs && playerDocs.docs.map((doc) => ({
                  key: doc.id,
                  columns: [
                    doc.id,
                    doc.data().score,
                    `${doc.data().answer.title || '...'}${checkAnswer(expectedTitle, doc.data().answer.title) ? ' ✅' : ''}`,
                    `${doc.data().answer.artist || '...'}${checkAnswer(expectedArtist, doc.data().answer.artist) ? ' ✅' : ''}`,
                  ],
                }))}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
