import React from 'react';

import Header from './utils/Header';

import './HomePage.css';

export default () => (
  <div className="Home">
    <Header />
    <div className="section section-hero bg-gray">
      <div className="grid-hero container grid-lg text-center">
        <h1>Secluded Games</h1>
        <h2>A free, social game hub.</h2>
        <p>
          <a className="btn btn-primary btn-lg" href="start">Start playing!</a>
        </p>
        <div className="columns cards">
          <div className="column col-4 col-xs-12">
            <div className="card text-center">
              <div className="card-header">
                <span className="card-title">Consensus</span>
              </div>
              <div className="card-body">A fun way to find out who someone is through the lens of the group. True or not.</div>
            </div>
          </div>
          <div className="column col-4 col-xs-12">
            <div className="card text-center">
              <div className="card-header">
                <span className="card-title">Robot Karaoke</span>
              </div>
              <div className="card-body">Guess the artist and title of popular Billboard 100 songs dating back to 1979 as sung by a robot.</div>
            </div>
          </div>
          <div className="column col-4 col-xs-12">
            <div className="card text-center">
              <div className="card-header">
                <span className="card-title">Telestrations</span>
              </div>
              <div className="card-body">A halarious virtual demonstration of telephone!</div>
            </div>
          </div>
          <div className="column col-4 col-xs-12">
            <div className="card text-center">
              <div className="card-header">
                <span className="card-title">Trivia</span>
              </div>
              <div className="card-body">Best your friends at a duel of wits. Trivia is an easy and fun way to see who&apos;s the ultimate know-it-all.</div>
            </div>
          </div>
          <div className="column col-4 col-xs-12">
            <div className="card text-center">
              <div className="card-header">
                <span className="card-title">In Sync</span>
              </div>
              <div className="card-body">Better known as Family Feud, is a fun way to see who thinks alike.</div>
            </div>
          </div>
          <div className="column col-4 col-xs-12">
            <div className="card text-center">
              <div className="card-header">
                <span className="card-title">Never Have I Ever</span>
              </div>
              <div className="card-body">The classic game in which you get to learn it all about your friends.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
