import React from 'react';

export default ({
  colNames,
  rows,
}) => (
  <table className="table table-hover">
    <thead>
      <tr>
        {colNames && colNames.map((colName) => (
          <th
            className="text-center"
            key={colName}
          >
            {colName}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {rows && rows.map((row) => (
        <tr key={row.key}>
          {row.columns && row.columns.map((column) => (
            <td
              className="text-center"
              key={column}
            >
              {column}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);
